import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFares from "../components/metro-fares"

class ServiceBR11Page extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="BR11"
          description="View route information and buy your ticket for the BR11 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">BR11 service</h1>
              <p>Woodlesford/Station Lane - Brigshaw High School</p>
              <p>Brigshaw High School – Woodlesford/Station Lane</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
              <h2>Route</h2>
                <p style={{ margin: '15px 0'}}>This service will pick up/set down at all regular bus stops along the following route:</p>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="BR11 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1sMzNSXYc0JLrJtY6Tthyf2h0yBpjpoM&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Brigshaw High School
                </p>
                <p>
                From Station Lane/Woodlesford Station via Station Lane, Church Street, Oulton Lane, Midland Street, Aberford Road , Calverley Road, North Lane, Holmsley lane, Leeds Road, Methley Lane, Church Side, Barnsdale Road, Pinfold Lane, Main Street, Mickletown Road, Savile Road, Pinfold Lane, Barnsdale Road to Barnsley Road/Pinfold Lane and then non-stop to school.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="BR11 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1Yt4swiYKogCPcFWMV9c1uJiMKIzrzU8&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Brigshaw High School
                </p>
                <p>
                From Brigshaw High School non-stop to Pinfold Lane/Barnsdale Road then via Pinfold Lane, Main Street, Mickletown Road, Savile Road, Pinfold Lane, Barnsdale Road, Church Side, Methley Lane, Leeds Road, Holmsley Lane, North Lane, Calverley Road, Aberford Road, Midland Street, Oulton Lane, Church Street and Station Lane.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Station Lane/Woodlesford Station</td>
                      <td>DEP</td>
                      <td>0715</td>
                    </tr>
                    <tr>
                      <td>Holmsley Lane/sixth Avenue</td>
                      <td>DEP</td>
                      <td>0725</td>
                    </tr>
                    <tr>
                      <td>Church Side/Church Lane</td>
                      <td>DEP</td>
                      <td>0740</td>
                    </tr>
                    <tr>
                      <td>Mickletown Road/Savile Road</td>
                      <td>DEP</td>
                      <td>0750</td>
                    </tr>
                    <tr>
                      <td>Barnsdale Road/Pinfold Lane</td>
                      <td>DEP</td>
                      <td>0755</td>
                    </tr>
                    <tr>
                      <td>Brigshaw High School</td>
                      <td>ARR</td>
                      <td>0820</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Brigshaw High School</td>
                      <td>DEP</td>
                      <td>1510</td>
                    </tr>
                    <tr>
                      <td>Barnsdale Road/Pinfold Lane</td>
                      <td>DEP</td>
                      <td>1530</td>
                    </tr>
                    <tr>
                      <td>Mickletown Road</td>
                      <td>DEP</td>
                      <td>1540</td>
                    </tr>
                    <tr>
                      <td>Church Side/Church Lane</td>
                      <td>DEP</td>
                      <td>1550</td>
                    </tr>
                    <tr>
                      <td>Holmsley Lane/sixth Avenue</td>
                      <td>DEP</td>
                      <td>1605</td>
                    </tr>
                    <tr>
                      <td>Station Lane/Woodlesford Station</td>
                      <td>ARR</td>
                      <td>1615</td>
                    </tr>
                  </tbody>
                </table>

                <MetroFares />
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default ServiceBR11Page
